<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>服务中心</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="foot-detail">
      <h3>服务中心</h3>
      <el-row :gutter="15" class="service-box">
        <el-col :span="8">
          <div class="item">
            <span class="icon-user2"></span>
            <h4>注册与登录</h4>
            <p>
              1、注册的用户名请使用5-14位字母（不区分大小写）或者数字组合；密码请使用6-20位的字母（区分大小写）或数字组合。
            </p>
            <p>2、注册成功后请登录使用相关资源。</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <span class="icon-qrcode"></span>
            <h4>二维码</h4>
            <p>1、注册成为平台用户；</p>
            <p>2、购买《安全驾驶从这里开始》教材，扫描封底二维码进行注册；</p>
            <p>3、登录后可享受“二维码专区”的增值服务。</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <span class="icon-clock1"></span>
            <h4>学时</h4>
            <p>1、小车第一部分（科目一）理论培训课程，共8学时；</p>
            <p>2、小车第四部分（科目三理论）理论培训课程，共8学时。</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <span class="icon-film4"></span>
            <h4>视频播放</h4>
            <p>1、初次学习不能快进，只能正常观看，重复学习时可拖拽任意学习。</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <span class="icon-file-text"></span>
            <h4>考题</h4>
            <p>1、小车第一部分（科目一）共1330道题目。</p>
            <p>2、小车第四部分（科目三理论）共1128道题目。</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <span class="icon-recognition"></span>
            <h4>人脸识别</h4>
            <p>1、首先保证有摄像头。</p>
            <p>2、在个人信息中采集照片；</p>
            <p>3、学习过程中进行人脸验证，验证成功可继续学习。</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <span class="icon-browser1"></span>
            <h4>浏览器</h4>
            <p>1、支持IE8及以上，firefox、Chrome浏览器。</p>
            <p>2、为了更好地记录学时，建议使用chrome浏览器。</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <span class="icon-car2"></span>
            <h4>驾校</h4>
            <p>1、请在注册或者个人信息中，先选择驾校所在地区。</p>
            <p>2、请选择您所报考的驾校。</p>
            <p>3、注：如果下拉列表中没有您所报考的驾校名称，请不要绑定。</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <span class="icon-map-signs"></span>
            <h4>学习向导</h4>
            <p>1、请在注册或者个人信息中，先选择驾校所在地区。</p>
            <p>2、请选择您所报考的驾校。</p>
            <p>3、注：如果下拉列表中没有您所报考的驾校名称，请不要绑定。</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home'
}
</script>
